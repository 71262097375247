.App {
  text-align: center;
  font-family: 'Work Sans', sans-serif;
  font-weight: 200;
  max-width: 1000px;
  margin: auto;
}

.Header {
  display: flex;
  flex-wrap: wrap;
  max-width: 700px;
  margin: 20px auto auto;
}

.Header img {
  height: 90px;
}

@media only screen and (max-width: 600px) {
  .Header {
    flex-direction: column;
  }
  .Header img {
    height: auto;
    width: 300px;
    margin: auto;
  }
}

.HeaderItem {
  padding: 2px 10px;
  margin: auto;
}

.HeaderItem__active {
  padding: 2px 10px;
  margin: auto;
  color: #7192BE;
  font-weight: 400;
}

.HeaderItem:hover {
  color: #7192BE;
}

/* FOOTER */

.Footer {
  display: flex;
  flex-wrap: wrap;
  padding: 100px 20px 20px;
}

.Footer img {
  height: 50px;
  margin-right: 20px;
}

/* WELCOME */

.Welcome {
  padding: 20px;
  text-align: left;
}

/* TEATMENTS */

.TreatmentsList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.TreatmentsInfo {
  padding: 20px;
  text-align: left;
}

.TreatmentItem {
  max-width: 300px;
  padding: 5px;
  margin-right: 10px;
}

.TreatmentItem h3 {
  text-align: left;
}

.TreatmentItem p {
  text-align: left;
}


/* REVIEWS */

.Reviews {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ReviewItem {
  display: flex;
  flex-wrap: wrap;
  max-width: 300px;
  padding: 5px;
  margin-right: 10px;
}

.ReviewItem__name {
  font-weight: 400;
  margin: auto;
}

.ReviewItem__content span {
  font-size: 20px;
  font-family: cursive;
  margin: 0 2px;
}

/* CONTACT */

.Contact {
  padding: 20px;
}

.Contact__address {
  margin-bottom: 30px;
}

.Contact__3 {
  margin-bottom: 40px;
}

.Contact__3 span{
  font-weight: 300;
}

.Contact a {
  text-decoration: none;
  color: #7192BE;
}


/* TERMS */

.Terms {
  padding: 20px;
  text-align: left;
}

.Terms h3 {
  font-weight: 300;
}